import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import StepsToStart from "../sections/StepsToStart";

import Seo from "../components/Seo";
import InstagramFeed from "../components/InstagramFeed";
import HeroHeader from "../components/HeroHeader";
import OurLocations from "../sections/OurLocations";

const FAQPage = ({ data }) => {
  const faq = data.markdownRemark;

  return (
    <Layout>
      <Seo
        title={faq.frontmatter.title}
        meta_title={faq.frontmatter.meta_title}
        meta_desc={faq.frontmatter.meta_description}
        slug={faq.fields.slug}
      />
      <main>
        <div>
          {/* Hero card */}
          <HeroHeader title={faq.frontmatter.title} options={[]} />
          {/* End hero */}

          {/* Training with FEAT Fitness */}
          <div className="py-12 md:py-16 px-6 sm:px-32 text-feat-darkgray text-center max-w-screen-lg mx-auto">
            <h2 className="font-heading uppercase text-3xl text-feat-darkgray text-center">
              {faq.frontmatter.question}
            </h2>
            <p className="pt-8 font-light">{faq.frontmatter.answer}</p>
          </div>
          {/* END Training with FEAT Fitness */}

          {/* Map and Location Details */}
          <OurLocations />
          {/* END Map and Location Details */}

          {/* Training with FEAT Fitness */}
          <StepsToStart />
          {/* END Training with FEAT Fitness */}

          {/* Instagram Feed */}
          <InstagramFeed />
          {/* End Instagram Feed */}
        </div>
      </main>
    </Layout>
  );
};

export default FAQPage;

export const pageQuery = graphql`
  query FAQByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        meta_title
        meta_description
        question
        answer
      }
    }
  }
`;
